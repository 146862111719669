
  import { PropType } from "@vue/runtime-core";
  import { Options, Vue } from "vue-class-component";

  @Options({
    props: {
      uniqueRef: { type: String, default: 'info-img' },
      title: { type: String, default: '' },
      copy: { type: String, default: '' },
      imageSrc: { type: String, default: '' },
      imageAlt: { type: String, default: '' },
      imageLocation: { type: String as PropType<'left' | 'right'> },
      hideImageMobile: { type: Boolean, default: false }
    }
  })

  export default class InfoSection extends Vue {
    uniqueRef!: string;
    title!: string;
    copy!: string;
    imageHeight = 'auto';
    isCopyNull: boolean = false
    
    mounted() {
      window.addEventListener("load", () => this.setBgImageHeight());
      window.addEventListener("resize", () => this.setBgImageHeight());
      this.setBgImageHeight();

      var infoCopy: any = this.$refs.infocopy
      this.isCopyNull = infoCopy.childElementCount < 1 && this.title == '' && this.copy == ''
    }

    setBgImageHeight() {
      if(this.uniqueRef) {
        const ref = (this.$refs as any)[this.uniqueRef as any]
        this.imageHeight = ref ? `${ref.offsetHeight}px` : `auto`;
      }
    }
  }

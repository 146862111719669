
  import { Options, Vue } from "vue-class-component";
  import { useMeta } from "vue-meta";
  import { IReCaptchaComposition, VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";

  @Options({
    beforeRouteUpdate(to, from, next) {
      this.hideRecaptcha();
    },
  })
  export default class App extends Vue {
    recapthcaObject: IReCaptchaComposition | undefined = undefined;

    mounted() {
      this.recapthcaObject = useReCaptcha() as IReCaptchaComposition;
      const { meta } = useMeta({
        title: "",
        description: "",
        keywords: "",
        og: {
          title: '',
          description: '',
          type: '',
          url: ''
        },
        htmlAttrs: { lang: 'en', amp: true }
      });

      this.hideRecaptcha();
    }

    async hideRecaptcha() {
      if (this.recapthcaObject) {
        // Wait until recaptcha has been loaded.
        await this.recapthcaObject.recaptchaLoaded();

        // Hide recaptcha has been loaded.
        const reCapthca = document.querySelector(".grecaptcha-badge") as any;
        if (reCapthca && !["ResidentialContactUs", "ResidentialWarrantyRegistration"].includes(this.$route.name as string)) {
          reCapthca.style.visibility = `hidden`;
        } else {
          reCapthca.style.visibility = `visible`;
        }
      }
    }
  }


  import { mixins, Options, Vue } from "vue-class-component";
  import CommercialBanner from "@/components/banners/CommercialBanner.vue";
  import { useMeta } from "vue-meta";
  import { SetMetaData } from "@/mixins/utilities";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Autoplay } from 'swiper/modules';
  import 'swiper/css';

  @Options({
    components: {
      CommercialBanner,
      Swiper,
      SwiperSlide,
    },
  })
  export default class HomeView extends mixins(SetMetaData) {
    swiperModules = [Autoplay];
    autoplaySettings = {
      delay: 2000,
      disableOnInteraction: false,
      waitForTransition: false
    };
    partnerBreakpoints: any = {
      0: {
        slidesPerView: 1,
        spaceBetween: 5,
        centeredSlides: true
      },
      300: {
        slidesPerView: 1.3,
        spaceBetween: 20,
      },
      460: {
        slidesPerView: 2
      },
      700: {
        slidesPerView: 3.75,
        spaceBetween: 35
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 50,
        centeredSlides: false
      },
      1200: {
        slidesPerView: 4.5
      },
      1400: {
        slidesPerView: 5
      }
    };

    created() {
      const { meta } = useMeta({});
      this.setMetaData(meta, {}, "", "Home | ");
    }
  }

import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-444664ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row justify-content-between align-items-center", {'flex-lg-row-reverse': _ctx.imageLocation === 'left'}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["col-12", {'col-lg-5 col-lg-6': _ctx.imageSrc !== '' && !_ctx.isCopyNull }]),
      ref: "infocopy"
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h2", {
              key: 0,
              class: "title mb-3 mb-sm-4",
              innerHTML: _ctx.title
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.copy)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "copy",
              innerHTML: _ctx.copy
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ], true)
    ], 2),
    (_ctx.imageSrc)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["col-12", {'col-lg-5 col-lg-6': !_ctx.isCopyNull, 'd-none d-lg-block': _ctx.hideImageMobile }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["info-section-img-container h-100 mt-4 mt-lg-0 text-center", { 'text-lg-start': _ctx.imageLocation === 'left', 'text-lg-end' : _ctx.imageLocation === 'right' }])
          }, [
            _createElementVNode("img", {
              class: "img-fluid h-100 d-none d-lg-inline",
              style: _normalizeStyle(`height: ${_ctx.imageHeight}`),
              src: `${require('@/assets/' + _ctx.imageSrc.replace(/^\//g, ''))}`,
              alt: _ctx.imageAlt || ''
            }, null, 12, _hoisted_3),
            _createElementVNode("img", {
              class: "img-fluid w-100 d-inline d-lg-none",
              src: `${require('@/assets/' + _ctx.imageSrc.replace(/^\//g, ''))}`,
              alt: _ctx.imageAlt || ''
            }, null, 8, _hoisted_4)
          ], 2)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
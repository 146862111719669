import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ResidentialHomeView from '../views/residential/HomeView.vue';
import ResidentialLayout from "@/layout/Residential.vue";
import CommercialLayout from "@/layout/Commercial.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/residential",
    component: ResidentialLayout,
    meta: {
      title: "Magneto Residential",
    },
    children: [
      {
        path: '/residential',
        name: 'ResidentialHome',
        component: ResidentialHomeView
      },
      {
        path: '/residential/about',
        name: 'ResidentialAboutUs',
        component: () => import(/* webpackChunkName: "AboutUs" */ '../views/residential/AboutView.vue')
      },
      {
        path: '/residential/contact-us',
        name: 'ResidentialContactUs',
        component: () => import(/* webpackChunkName: "Apply" */ '../views/residential/ApplyView.vue')
      },
      {
        path: '/residential/warranty-registration',
        name: 'ResidentialWarrantyRegistration',
        component: () => import(/* webpackChunkName: "WarrantyRegistration" */ '../views/residential/WarrantyRegistrationView.vue')
      },
      {
        path: '/residential/terms-and-conditions',
        name: 'ResidentialTermsAndConditions',
        component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../views/residential/TermsAndConditionsView.vue')
      },
      {
        path: '/residential/products/:category?',
        name: 'ResidentialProductRange',
        component: () => import(/* webpackChunkName: "ProductRange" */ '../views/residential/ProductRangeView.vue'),
        props: true
      },
      {
        path: '/residential/product/:sku?',
        name: 'ResidentialProduct',
        component: () => import(/* webpackChunkName: "Product" */ '../views/residential/ProductView.vue'),
        props: true
      },
      {
        path: '/residential/solutions',
        name: 'ResidentialSolutions',
        component: () => import(/* webpackChunkName: "Solutions" */ '../views/residential/SolutionsView.vue')
      },
      {
        path: '/residential/solutions/:slug',
        name: 'ResidentialSolution',
        component: () => import(/* webpackChunkName: "Solution" */ '../views/residential/SolutionInfoView.vue'),
        props: true
      }
    ]
  },
  {
    path: "/",
    component: CommercialLayout,
    meta: {
      title: "Magneto Commercial",
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/404',
        name: 'PageNotFound',
        component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFoundView.vue')
      },
    ]
  },
  {
    path: '/not-found',
    name: 'ResidentialPageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/residential/PageNotFoundView.vue')
  },
  {
    path: '/residential/:pathMatch(.*)*',
    redirect: "/not-found"
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  },
  {
    path: '/about',
    redirect: "/residential/about"
  },
  {
    path: '/apply',
    redirect: "/residential/apply"
  },
  {
    path: '/warranty-registration',
    redirect: "/residential/warranty-registration"
  },
  {
    path: '/products/:pathMatch(.*)*',
    redirect: "/residential/products"
  },
  {
    path: '/product/:pathMatch(.*)*',
    redirect: "/residential/products"
  },
  {
    path: '/solutions',
    redirect: "/residential/solutions"
  },
  {
    path: '/solutions/:pathMatch(.*)*',
    redirect: "/residential/solutions"
  },
  {
    path: '/contact-us',
    redirect: "/residential/contact-us"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition
    // For anchors
    } else if (to.hash) {
      return { el: to.hash }
    // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {}
    } 
    else if ((from.name === 'ResidentialProductRange') && (to.name === 'ResidentialProductRange')) {
      return { el: '#product-range' , top: 90 }
    }

    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { top: 0, behavior: 'smooth' }; 
  }
})

router.beforeEach(() => {
  document.getElementById("Nav")?.classList?.remove("show");
});

router.afterEach(() => {
  if(router.currentRoute.value.name && !['ContactUs', 'WarrantyRegistration', 'TermsAndConditions'].includes(router.currentRoute.value.name as string)) {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.visibility = 'hidden';
    }
  } else {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.visibility = `visible`;
    }
  }
});

export default router

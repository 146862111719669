
  import { mixins, Options, Vue } from "vue-class-component";
  import CarouselBanner from "@/components/banners/CarouselBanner.vue";
  import GenericBanner from "@/components/banners/GenericBanner.vue";
  import SolarSignupLinkBanner from "@/components/banners/SolarSignupLinkBanner.vue";
  import InfoSection from "@/components/misc/InfoSection.vue";
  import PowerSolutionBlock from "@/components/misc/PowerSolutionBlock.vue";
  import { useMeta } from "vue-meta";
  import { SetMetaData } from "@/mixins/utilities";
  import { CarouselBannerImageData } from '@/types/index';

  @Options({
    components: {
      CarouselBanner,
      InfoSection,
      PowerSolutionBlock,
      GenericBanner,
      SolarSignupLinkBanner,
    },
  })
  export default class HomeView extends mixins(SetMetaData) {
    showTevoLabel = false;
    homeBannerImages: CarouselBannerImageData[] = [
      {
        slot: `
        <a class="d-block" style="pointer-events: all;">
          <img src="${require('@/assets/home/promotion/MRE_solis+evo_HomeBanner.png')}"
            class="d-block w-100"
            alt="Solus + Evo Banner"/>
        </a>`,
      },
      //
      // {
      //   src: "home/home-page-banner-1.jpg",
      //   alt: "Magneto Energy",
      //   heading: 'Power for <br class="d-none d-sm-block" />peace of mind',
      //   headingClass: "center-left text-white",
      //   copy: `Magneto Renewable Energy provides a wide <br class="d-none d-sm-block" />
      //   range of long-lasting, fast-charging, <br class="d-none d-sm-block" />
      //   ultra-efficient energy storage solutions to home <br class="d-none d-sm-block" />
      //   owners, commercial and industrial businesses.`,
      // }
    ];
    introSection = {
      textAlign: "center",
      imageSrc: "home/about-image-1.jpg",
      imageAlt: "About Magneto Energy",
      imageLocation: "left",
      hideImageMobile: false,
      title: 'A BRIGHTER FUTURE, WHERE THE POWER BELONGS TO YOU!',
      copy: '',
    };

    created() {
      const { meta } = useMeta({});
      this.setMetaData(meta, {}, "", "Home | ");
    }
  }

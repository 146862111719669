
  import { Vue } from 'vue-class-component';
  
  export default class CommercialBanner extends Vue {
    slideCount: number = 0;

    mounted() {
      this.slideCount = document.querySelectorAll("#CommercialBanners .carousel-item").length;
    }

    get bannerHeight () {
      const navbar = document.getElementById("navbar");
      const introBar = document.getElementById("IntroBar");
      return (navbar?.offsetHeight || 90) + (introBar?.offsetHeight || 35);
    }
  }


  import { mixins } from "vue-class-component";
  import { ScrollTop } from "@/mixins/utilities";

  export default class App extends mixins(ScrollTop) {
    mounted() {
      if (this.$route.hash) {
        window.setTimeout(() => { this.scrollTop(this.$route.hash); }, 0);
      }
    }

    closeMenu() {
      document.getElementById("Nav")?.classList?.remove("show");
    }
  }


import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { RouteLocationRaw } from "vue-router";

@Options({
  props: {
    displayNumber: { type: String, default: "" },
    title: { type: String, default: "" },
    kilowattsHybrid: { type: String, default: "" },
    kilowattsLithium: { type: String, default: "" },
    url: { type: Object as PropType<RouteLocationRaw> },
  },
})
export default class PowerSolutionBlock extends Vue {
  displayNumber!: string;
  title!: string;
  kilowattsHybrid!: string;
  kilowattsLithium!: string;
  url!: RouteLocationRaw;

  get imgUrl() {
    return `general/power-solution-${this.displayNumber}.png`;
  }
}

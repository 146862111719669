
  import { Options, Vue } from "vue-class-component";
  import NavBar from "@/components/navigation/NavBar.vue";
  import AppMain from "@/components/AppMain.vue";
  import Footer from "@/components/navigation/Footer.vue";
  import { useMeta } from "vue-meta";

  @Options({
    components: {
      NavBar,
      AppMain,
      Footer
    },
  })
  export default class App extends Vue {
    mounted() {
      const { meta } = useMeta({
        title: "",
        description: "",
        keywords: "",
        og: {
          title: '',
          description: '',
          type: '',
          url: ''
        },
        htmlAttrs: { lang: 'en', amp: true }
      });
    }
  }

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `Magneto Energy`), 1)
      ]),
      description: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `We harness natural power to create sustainable solutions that reduce energy costs to you and to our planet. We aim for power and peace of mind - priding ourselves on precise needs-based solution delivery, high-quality products, and excellent customer service anywhere in Africa.`), 1)
      ]),
      keywords: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : ``), 1)
      ]),
      "og(title)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `Magneto Energy`), 1)
      ]),
      "og(description)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `We harness natural power to create sustainable solutions that reduce energy costs to you and to our planet. We aim for power and peace of mind - priding ourselves on precise needs-based solution delivery, high-quality products, and excellent customer service anywhere in Africa.`), 1)
      ]),
      "og(type)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `website`), 1)
      ]),
      "og(url)": _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content}` : `https://www.magnetoenergy.co.za/`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_notifications, { position: "center center" }),
    _createVNode(_component_router_view)
  ]))
}
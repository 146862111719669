
  import { PropType } from '@vue/runtime-core';
  import { Options, Vue } from 'vue-class-component';
  import { CarouselBannerImageData } from '@/types/index';

  @Options({
    props: {
      images: { type: Array as PropType<Array<CarouselBannerImageData>> },
      uniqueId: { type: String, default: 'carouselBanner' }
    }
  })
  export default class CarouselBanner extends Vue { }
